// React and Hooks.
import React, { useState, createContext } from "react";
import { useQuery } from "@apollo/client";
import { useSessionStorage } from "react-use";
import { useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";

// Queries.
import QueryExtendedTeaserView from "../query-overview.graphql";
import moment from "moment";
import { useSelector } from "react-redux";
import { mappedWeekday } from "@default/paragraphs/overview/components/main-controls/mapped-weekdays";
import { festivalDayOffset } from "@default/js/lib/festival-day-offset";

// Create the overview context.
export const OverviewContext = createContext({});

// Default filter values.
export const defaultFilters = {
  combine: "",
  format: [],
  spoken_language: [],
  event_type: [],
  main_topic: [],
  weekday: [],
  branch: [],
  accessibility: [],
  genre: [],
  mood: [],
  participant_category: [],
};

/**
 * Overview Provider.
 * This provider manages the state of the overview, manages its main logic and provides the necessary
 * values and functions to the children/consumers.
 * The following operations are performed here:
 * - Load or initialize the current sort and filters from the URL search params.
 * - Load or initialize the current page, page mode, nodes, grouped nodes, metadata from the local storage.
 * - Perform the main nodes query.
 * - Reset the nodes, page, and scroll position.
 */
const OverviewProvider = ({ children, content }) => {
  /**
   * Toggle the scrollspy Modal from everywhere inside of the Overview.
   */
  const [scrollspyOpen, setScrollspyOpen] = useState(false);

  /**
   * useSearchParams hook to perform search query operations.
   */
  const [searchParams, setSearchParams] = useSearchParams();

  /**
   * Load the current page mode and metadata from the local storage.
   */
  const [pageMode, setPageMode] = useSessionStorage(
    "ov:mode",
    "endless-scroll"
  );

  /**
   * Extract the sort field and direction from the sort string.
   */
  const [sortField, sortDirection] = (
    searchParams.get("sort") || "field_date-ASC"
  ).split("-");

  // Set View ID Name by content type given
  let viewIdName = content.fieldContentType;

  // Participants will never show all Participants, they will be shown by type. So ask if Participant Type is given and use it
  // Obsolete: fieldParticipantType is always set to speaker -> @todo: remove
  if (content.fieldParticipantType) {
    viewIdName = content.fieldParticipantType;
  }

  // Events will need further distinction
  switch (content.entityBundle) {
    case "overview_timetable": {
      viewIdName = content.fieldViewType;
    }
  }

  const edition = content.fieldEdition?.id || "";

  const [currentViewId, setCurrentViewId] = useState(viewIdName);

  const type = content.fieldContentType ? [content.fieldContentType] : null;
  const viewId = `overview_${currentViewId}`;

  const festivalTime = useSelector(
    (reduxStore) => reduxStore.appStore.mainSettings?.fieldFestivalTime
  );

  // Check if Festival is Happening right now (Current Date is between Festival Time set in Main Settings)
  const festivalIsHappening = moment().isBetween(
    moment(festivalTime?.value, "YYYY-MM-DD"),
    moment(festivalTime?.endValue, "YYYY-MM-DD").endOf("day")
  );

  /*
   * Check if we should filter for current Day. Only True if festival is Happening AND there are no search params previously set.
   * Otherwise they would be overruled every time.
   */
  let filterForCurrentDay = festivalIsHappening && searchParams.size === 0;

  /*
   * Check if we should jump to current Time. Only True if:
   * festival is Happening
   * AND we are currently filtering for current Day
   * AND there is only the DayFilter search param previously set, which is the current day.
   * Otherwise they would be overruled every time.
   */

  const [offset, offsetUnit] = festivalDayOffset;
  let jumpToCurrentTime =
    festivalIsHappening &&
    searchParams.size === 1 &&
    searchParams.getAll("weekday")[0] ===
      mappedWeekday(moment().add(offset, offsetUnit));

  /**
   * Compare function for sorting the filters.
   * They are strings, so sort them like numbers.
   */
  const sfn = (a, b) => +a - +b;

  /**
   * Build the query variables object for the main nodes query.
   */
  const queryVariables = {
    viewId,
    type,
    page: 0,
    combine: searchParams.get("combine") || defaultFilters.combine,
    format: searchParams.getAll("format").sort(sfn),
    spoken_language: searchParams.getAll("spoken_language").sort(sfn),
    event_type: searchParams.getAll("event_type").sort(sfn),
    main_topic: searchParams.getAll("main_topic").sort(sfn),
    weekday: searchParams.getAll("weekday").sort(sfn),
    branch: searchParams.getAll("branch").sort(sfn),
    accessibility: searchParams.getAll("accessibility").sort(sfn),
    genre: searchParams.getAll("genre").sort(sfn),
    mood: searchParams.getAll("mood").sort(sfn),
    participantCategory: searchParams.getAll("participant_category").sort(sfn),
    planned: searchParams.getAll("weekday").length ? "1" : null,
    edition,
    sortField,
    sortDirection,
  };

  if (viewId === "overview_news") {
    queryVariables.news_type = [content.fieldNewsType];
  }

  if (viewId === "overview_speaker") {
    queryVariables.branch = content.fieldBranch;
  }

  /**
   * Main nodes query.
   * The nodes are fetched based on the current view, filters, sort, and page.
   * This is skipped if the query hash stored in the local storage won't change.
   */
  const { data, loading, error, fetchMore } = useQuery(
    QueryExtendedTeaserView,
    {
      variables: { ...queryVariables },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-first",
      nextFetchPolicy: "cache-first",
    }
  );

  /**
   * Update metadata for pager
   */
  const metaData = {
    totalRows: data?.entityById.executable.execute.total_rows || 0,
    perPage: data?.entityById.executable.pager.perPage || 1,
  };

  /**
   * Update nodes array
   */
  const nodes = data?.entityById.executable.execute.rows;

  /**
   * Load more nodes.
   * This function automatically increases the page, fetches more nodes, and updates the nodes state.
   * It is used in scrollspy and endless scroll.
   * @returns {Promise<[]>} - New nodes.
   */

  //@todo: only use one "loadMoreNodes" Function. There is another one in endless-scroll.
  const loadMoreNodes = async (_nodes) => {
    const newPage = Math.ceil(_nodes.length / metaData.perPage);
    let newNodes = [];

    await fetchMore({
      variables: { page: newPage },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        const updatedData = Object.assign({}, prev, {
          entityById: {
            ...prev.entityById,
            executable: {
              ...prev.entityById?.executable,
              execute: {
                total_rows:
                  fetchMoreResult.entityById.executable.execute.total_rows,
                rows: [
                  ...(prev.entityById?.executable?.execute?.rows || []),
                  ...(fetchMoreResult.entityById?.executable?.execute?.rows ||
                    []),
                ],
              },
            },
          },
        });
        newNodes = updatedData.entityById?.executable?.execute.rows;
        return updatedData;
      },
    });

    return newNodes;
  };

  /**
   * The context value provides all necessary values and functions.
   */
  const value = {
    ...content,
    scrollspyOpen,
    setScrollspyOpen,
    metaData,
    currentViewId,
    setCurrentViewId,
    searchParams,
    setSearchParams,
    sfn,
    pageMode,
    setPageMode,
    nodes,
    loadMoreNodes,
    fetchMore,
    loading,
    error,
    festivalIsHappening,
    filterForCurrentDay,
    jumpToCurrentTime,
  };

  return (
    <OverviewContext.Provider value={value}>
      {children}
    </OverviewContext.Provider>
  );
};

OverviewProvider.propTypes = {
  children: PropTypes.node,
  content: PropTypes.shape({
    fieldEdition: PropTypes.shape({
      id: PropTypes.string,
    }),
    fieldContentType: PropTypes.oneOf([
      "article",
      "event",
      "news",
      "project",
      "venue",
      "participant",
    ]),
    fieldBranch: PropTypes.oneOf(["conference", "festival", ""]),
    fieldParticipantType: PropTypes.oneOf(["act", "speaker", "venue"]),
    fieldViewType: PropTypes.string,
    entityBundle: PropTypes.oneOf([
      "overview_acts",
      "overview_speakers",
      "overview_venues",
      "overview_timetable",
    ]),
    fieldNewsType: PropTypes.oneOf(["news", "press"]),
  }),
};

export default OverviewProvider;
