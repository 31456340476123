import React from "react";
import { useQuery } from "@apollo/client";
import relatedNodesQuery from "./related-nodes-query.graphql";

import TeaserParticipantGeneralRelated from "@default/teasers/participant-general/teaser-participant-general-related";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import Link from "./../../../general-components/link/link";
import LoadingIndicator from "@default/general-components/loading-indicator";
import { useNavigate } from "react-router-dom";

import PropTypes from "prop-types";

const Related = ({ content, type }) => {
  const mainSettings = useSelector(
      (reduxStore) => reduxStore.appStore.mainSettings
    ),
    customHistory = useSelector(
      (reduxStore) => reduxStore.appStore.customHistory
    ),
    navigate = useNavigate();

  // Check if User comes from Overview Page or not
  let userComesFromOverviewPage =
    (mainSettings.fieldConferenceOverviewPage &&
      type === "event" &&
      mainSettings.fieldConferenceOverviewPage?.uri?.path ===
        customHistory[customHistory.length - 2]?.pathname) ||
    (mainSettings.fieldFestivalOverviewPage &&
      type === "act" &&
      mainSettings.fieldFestivalOverviewPage?.uri?.path ===
        customHistory[customHistory.length - 2]?.pathname);

  /**
   * Get current event type, if parent is event.
   */
  const eventType = [content.fieldEventType?.id].filter(Boolean);

  /**
   * Get current genre, if parent is act.
   */
  const genre = content.fieldGenre?.length
    ? content.fieldGenre?.map((item) => item.id.toString())
    : [];

  /**
   * Set the view id based on the type.
   */
  let view;
  if (type === "event") view = "related_events";
  if (type === "act") view = "related_acts";

  /**
   * Related nodes query.
   */
  const { data, loading, error } = useQuery(relatedNodesQuery, {
    variables: {
      view,
      excludedId: content.id,
      eventType,
      genre,
    },
    skip: !content.id || !type,
  });

  const relatedContent = data?.entityById?.executable?.execute?.rows || [];

  if (error) {
    console.error(error);
    return null;
  }

  if (relatedContent?.length === 0 && !loading) {
    return null;
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  if (relatedContent) {
    return (
      <div className="related-content" data-type={type}>
        <div className="container">
          <div className="row">
            <h3
              className={`col-16 ${
                type === "event" ? "headline-l" : "headline-xl"
              }`}
            >
              <FormattedMessage id={"related"} />
            </h3>
          </div>
        </div>
        <div className={"container max-width"}>
          <div className="row">
            <div
              className={`col-16 ${
                type === "event" ? "related-events" : "related-acts"
              }`}
            >
              <div className="row teasers">
                {relatedContent.map((content, contentIndex) => (
                  <React.Fragment key={contentIndex}>
                    <TeaserParticipantGeneralRelated
                      item={content}
                      index={contentIndex}
                    />
                  </React.Fragment>
                ))}
              </div>
              {((mainSettings.fieldConferenceOverviewPage &&
                type === "event") ||
                (mainSettings.fieldFestivalOverviewPage && type === "act")) && (
                <div className={"overview-link d-flex justify-content-center"}>
                  {userComesFromOverviewPage ? (
                    <button
                      className="btn btn-primary more"
                      onClick={() => navigate(-1)}
                    >
                      {type === "act" ? (
                        <>{mainSettings.fieldFestivalOverviewPage.title}</>
                      ) : (
                        <>{mainSettings.fieldConferenceOverviewPage.title}</>
                      )}
                    </button>
                  ) : (
                    <button className="btn btn-primary more with-hover">
                      {type === "act" ? (
                        <Link
                          link={mainSettings.fieldFestivalOverviewPage}
                          forceSameTab={true}
                        />
                      ) : (
                        <Link
                          link={mainSettings.fieldConferenceOverviewPage}
                          forceSameTab={true}
                        />
                      )}
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

Related.propTypes = {
  /**
   * Content of the parent entity.
   */
  content: PropTypes.object.isRequired,
  /**
   * Type of the parent entity (act or event).
   */
  type: PropTypes.oneOf(["act", "event"]).isRequired,
};

export default Related;
