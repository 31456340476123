import React from "react";
import ErrorBoundary from "../../../../error-boundary";
import Appearances from "@default/nodes/shared-components/appearances/appearances";
import { FormattedMessage } from "react-intl";

const LinkedEvents = ({ content }) => {
  if (content.fieldNetworkingEvents?.length > 0) {
    return (
      <ErrorBoundary>
        <div className="linked-events col-16">
          <h4 className="headline make-headline-light body-m">
            <FormattedMessage id="event.linked" />
          </h4>
          {content.fieldNetworkingEvents?.map(
            (networkingEvent, networkingEventId) => (
              <React.Fragment key={networkingEventId}>
                <Appearances
                  detailedMode={true}
                  content={networkingEvent}
                  type={"linked-event"}
                  showAddToCalendar={false}
                  viewMode={"detail"}
                  showEndDate={true}
                  forceLongDayName={true}
                />
              </React.Fragment>
            )
          )}
        </div>
      </ErrorBoundary>
    );
  } else {
    return false;
  }
};

export default LinkedEvents;
