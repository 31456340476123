import React from "react";

import participantPageQuery from "../../participants/participant.graphql";
import Presenter from "../../participants/components/presenter";
import LookingFor from "../../participants/components/looking-for";
import SocialLinks from "../../participants/components/social-links";
import MetaInfos from "../../participants/components/meta-infos";
import Sponsors from "../../participants/components/sponsors";
import Appearances from "../shared-components/appearances/appearances";
import Related from "../../participants/components/related/related";
import NodeWrapper from "@default/nodes/shared-components/node-wrapper";
import useNodeQuery from "@default/hooks/use-node-query";
import SpotifyWidget from "@default/js/participants/components/spotify-widget/spotify-widget";
import DetailPageIntro from "@default/nodes/shared-components/detail-page-intro";
import CheckForChildren from "@default/lib/check-for-children";
import Credit from "@default/nodes/shared-components/credit";
import ParticipatingPersons from "@default/nodes/shared-components/participating-persons";
import MainPresenterTextInfo from "@default/nodes/shared-components/main-presenter-text-info";

const Act = () => {
  const { entity, loading, error } = useNodeQuery(participantPageQuery);

  return (
    <NodeWrapper entity={entity} loading={loading} error={error}>
      {entity && (
        <>
          <DetailPageIntro content={entity} mode={"act"} />
          <div className="container max-width main-container">
            <div className="row infos">
              <div className={"col-16 col-xl-11 appearances-wrapper"}>
                <Appearances
                  detailedMode={true}
                  content={entity}
                  type={"act"}
                  viewMode={"detail"}
                  showAddToCalendar={true}
                  showEndDate={true}
                />
              </div>

              <div className={"col-16 col-xl-5 meta-info-wrapper"}>
                <MetaInfos content={entity} />
              </div>
            </div>

            <div className="row text-wrapper">
              <MainPresenterTextInfo content={entity} />

              {entity?.fieldText && (
                <div className="col-16 text-wrapper">
                  <div
                    className={"text headline-subhead"}
                    dangerouslySetInnerHTML={{ __html: entity.fieldText }}
                  />
                </div>
              )}

              <Credit content={entity} />
            </div>

            <div className="row with-gaps">
              <CheckForChildren classes={"col-16 col-lg-8 left-col"}>
                <SpotifyWidget content={entity} />

                <LookingFor content={entity} />

                <Sponsors content={entity} />
              </CheckForChildren>

              <CheckForChildren classes={"col-16 col-lg-8 right-col"}>
                <Presenter content={entity} />

                <SocialLinks content={entity} />
              </CheckForChildren>

              <ParticipatingPersons content={entity} />
            </div>
          </div>
          <div className="container-fluid related-container">
            <Related content={entity} type={"act"} />
          </div>
        </>
      )}
    </NodeWrapper>
  );
};

Act.propTypes = {};

export default Act;
