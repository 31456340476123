import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage, FieldArray, useFormikContext } from "formik";
import classNames from "classnames";

import { states } from "../states";
import WebformElements from "../webform-elements";
import { getInitialValue } from "@default/paragraphs/webform/paragraph-form";
import { AnimatePresence, motion } from "framer-motion";

const CompositeField = ({ item, token, generatedInitialValues }) => {
  const { values } = useFormikContext();

  const { invisible, visible, optional, required } = states(
    item["#states"],
    values
  );

  const initialValue = values[item.id] || [];

  let entityLabel = item["#title"].toLowerCase();
  if (entityLabel === "guestlist") {
    entityLabel = "guest";
  }

  return (
    <div
      className={classNames({
        "form-group custom-composite": true,
        hidden: invisible || !visible,
      })}
      style={item["#flex"] ? { flex: item["#flex"] } : {}}
    >
      <label htmlFor={item.id}>
        <h6>
          {item["#title"]}
          {(!!item["#required"] || required) && !optional && visible && (
            <span className="required">*</span>
          )}
        </h6>
      </label>

      {!!item["#description"] && (
        <small
          className="form-description text-muted form-text"
          dangerouslySetInnerHTML={{ __html: item["#description"] }}
        />
      )}

      {/* @see https://formik.org/docs/api/fieldarray */}
      <FieldArray
        name={item.id}
        render={(arrayHelpers) => (
          <>
            <div className="container-fluid">
              <div className="form-row row">
                <AnimatePresence>
                  {[...Array(initialValue.length)].map((i, index) => (
                    <motion.div
                      key={`composite-${item.id}-${index}`}
                      className="col-12 col-md-6 composite-outer-wrapper"
                      style={{
                        padding: "1em",
                        border: "1px solid grey",
                      }}
                      initial={{ scale: 0, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      exit={{ scale: 0, opacity: 0 }}
                      transition={{ duration: 0.25, ease: "easeOut" }}
                    >
                      <div className="composite">
                        <WebformElements
                          items={item.elements}
                          token={token}
                          compositeIndex={item["#multiple"] ? index : undefined}
                          compositeParent={item.id}
                        />

                        {item["#multiple"] ? (
                          <div style={{ marginTop: "1em" }}>
                            <button
                              type="button"
                              className="btn"
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              Remove {entityLabel}
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </motion.div>
                  ))}
                </AnimatePresence>
              </div>
            </div>

            {!!item["#multiple"] &&
            (item["#multiple"] === 0 ||
              item["#multiple"] > initialValue.length) ? (
              <div className="new-composite" style={{ marginTop: "1em" }}>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => arrayHelpers.push(getInitialValue(item)[0])}
                >
                  Add {entityLabel}
                </button>
              </div>
            ) : null}
          </>
        )}
      />

      <ErrorMessage
        role="region"
        aria-live="polite"
        component="span"
        name={item.id}
        className="error-message"
      />
    </div>
  );
};

CompositeField.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    "#states": PropTypes.object,
    "#flex": PropTypes.string,
    "#title": PropTypes.string,
    "#description": PropTypes.string,
    "#required": PropTypes.object,
    "#multiple": PropTypes.number,
    elements: PropTypes.array,
  }),
  token: PropTypes.string.isRequired,
  generatedInitialValues: PropTypes.object,
};

export default CompositeField;
