import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import EditButton from "../../../backend/edit-button";
import classNames from "classnames";
import Appearances from "@default/nodes/shared-components/appearances/appearances";

import Hyphenated from "react-hyphen";
import de from "hyphenated-de";

/**
 * @todo use "FieldTime"
 */
const TeaserEvent = ({ item, ammount }) => {
  const adminApp = useSelector((reduxStore) => reduxStore.appStore.adminApp);
  const location = useLocation();
  const teaser = useRef();

  let teaserClassNames = classNames({
    "node node-teaser teaser-event col-16 d-flex": true,
    "col-lg-custom small": ammount >= 2,
    "col-lg-8 big": ammount <= 2,
  });

  return (
    <article className={teaserClassNames} ref={teaser}>
      <EditButton
        adminApp={adminApp}
        entityId={item.entityId}
        destinationRoute={location.pathname}
      />

      <Link to={item?.url.path || item?.path.alias}>
        <div className="color-wrapper" />

        <div className="type body-m make-headline-light">
          {item.fieldEventType.name}
        </div>

        <div className="title-wrapper">
          <h3
            className={classNames({
              headline: true,
              "headline-s": item.title.length >= 50,
              "headline-m": item.title.length <= 50,
              "headline-l": item.title.length <= 15,
            })}
          >
            <Hyphenated language={de}>{item.title}</Hyphenated>
          </h3>

          {item.fieldSubline && (
            <h4 className="subline body-m make-headline-light">
              {item.fieldSubline}
            </h4>
          )}
        </div>

        <div className="info-wrapper">
          <Appearances
            detailedMode={false}
            content={item}
            type={"event"}
            showEndDate={false}
            showAddToCalendar={false}
          />
        </div>
      </Link>
    </article>
  );
};

export const teaserEventPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldLocation: PropTypes.string,
  fieldShowDuration: PropTypes.bool,
  fieldText: PropTypes.string.isRequired,
  fieldImage: PropTypes.shape({
    fieldMediaImage: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      style: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
  fieldDate: PropTypes.shape({
    value: PropTypes.string,
    endValue: PropTypes.string,
  }),
  fieldTags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  fieldTicket: PropTypes.shape({
    uri: PropTypes.shape({
      path: PropTypes.string,
    }),
  }),
});

TeaserEvent.propTypes = {
  item: teaserEventPropTypes,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default TeaserEvent;
