import React from "react";
import { useSelector } from "react-redux";
import personPageQuery from "./person.graphql";
import useNodeQuery from "@default/hooks/use-node-query";
import NodeWrapper from "@default/nodes/shared-components/node-wrapper";
import PagerFullPage from "@default/js/general-components/pager-full-page";
import ContentBase from "@default/general-components/content-base/content-base";

const Person = () => {
  const pagerConfig = useSelector(
    (reduxStore) => reduxStore.appStore.pagerFullPages
  );
  const pagerFullPageContextId = useSelector(
    (reduxStore) => reduxStore.appStore.pagerFullPageContextId
  );

  const { entity, loading, error } = useNodeQuery(personPageQuery);

  // Add person-infos per default.
  const modules = {
    list: [
      {
        entity: {
          entityBundle: "person_information",
        },
      },
      ...(entity?.fieldModules.list || []),
    ],
  };

  return (
    <NodeWrapper entity={entity} loading={loading} error={error}>
      <ContentBase content={modules.list} nodeContent={entity} />

      {pagerFullPageContextId &&
        pagerConfig.filter((config) => config.id === pagerFullPageContextId)
          .length > 0 && (
          <PagerFullPage
            config={
              pagerConfig.filter(
                (config) => config.id === pagerFullPageContextId
              )[0]
            }
            currentId={entity.entityId}
          />
        )}
    </NodeWrapper>
  );
};

Person.propTypes = {};

export default Person;
