const sortByDate = (arr) => {

  console.log("sortByDate")
  // Create a shallow copy of the array before sorting to avoid modifying the original array
  // Remove any falsy values from the array (e.g. null, undefined, empty strings)
  const arrCopy = [...(arr.filter(Boolean))];

  // Split the array into elements with and without fieldDateRawField
  const withDate = arrCopy.filter(item => item.fieldDateRawField?.first?.value);
  const withoutDate = arrCopy.filter(item => !item.fieldDateRawField?.first?.value);

  // Sort elements with fieldDateRawField by the date (ascending)
  const sortedWithDate = withDate.sort((a, b) => {
    const dateA = parseInt(a.fieldDateRawField?.first.value, 10);
    const dateB = parseInt(b.fieldDateRawField?.first.value, 10);
    return dateA - dateB;
  });

  // Sort elements without fieldDateRawField by label (ascending)
  const sortedWithoutDate = withoutDate.sort((a, b) => {
    const labelA = a.label.toLowerCase();
    const labelB = b.label.toLowerCase();
    return labelA.localeCompare(labelB);
  });

  // Combine the sorted arrays (withDate first, withoutDate last)
  return [...sortedWithDate, ...sortedWithoutDate];
};

export { sortByDate };