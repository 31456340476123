import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Slider from "react-slick";
import ErrorBoundary from "@default/js/error-boundary";
import Image from "@default/general-components/image/image";
import { FormattedMessage } from "react-intl";
import ArrowRight from "@default/assets/rbx-company/arrow-right-white.svg";
import Hyphenated from "react-hyphen";
import de from "hyphenated-de";

const SliderContent = ({ item }) => {
  return (
    <div
      className={`slide ${item.fieldImage ? "with-image" : "without-image"}`}
    >
      <div className="container">
        <div className="row">
          <div className="col-16 col-md-14 offset-md-1 quote">
            <blockquote>
              <Hyphenated language={de}>
                <div
                  className="text headline-s"
                  dangerouslySetInnerHTML={{
                    __html: item.fieldText,
                  }}
                />
              </Hyphenated>
              <cite className="body-m">{item.fieldSource}</cite>
            </blockquote>
            {item.fieldImage?.fieldMediaImage && (
              <div className="image-col">
                <ErrorBoundary>
                  <Image
                    data={item.fieldImage?.fieldMediaImage}
                    className="quote-image"
                  />
                </ErrorBoundary>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const ParagraphQuoteCarousel = ({ content }) => {
  const slider = useRef(),
    [sliderSettings, setSliderSettings] = useState({
      adaptiveHeight: true,
      arrows: true,
      dots: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      touchMove: true,
    }),
    sectionClassNames = classNames({
      "paragraph paragraph-quote-carousel": true,
    });

  return (
    <section className={sectionClassNames}>
      {content.fieldQuotes && content.fieldQuotes[0] && (
        <>
          {content.fieldQuotes?.length > 1 ? (
            <Slider {...sliderSettings} ref={slider}>
              {content.fieldQuotes &&
                content.fieldQuotes.map((item, index) => (
                  <React.Fragment key={index}>
                    <SliderContent item={item} />
                  </React.Fragment>
                ))}
            </Slider>
          ) : (
            <SliderContent item={content.fieldQuotes[0]} />
          )}
        </>
      )}

      {content.fieldQuotes.length > 1 && (
        <button
          className="quote-toggle d-flex flex-wrap"
          tabIndex={-1}
          aria-label={"next"}
          onClick={() => slider.current.slickNext()}
        >
          <span className="text body-button">
            <FormattedMessage id="further" />
          </span>
          <span className="arrow-wrapper body-button">
            <img src={ArrowRight} alt={"arrow right"} className="arrow" />
          </span>
        </button>
      )}
    </section>
  );
};

ParagraphQuoteCarousel.propTypes = {
  content: PropTypes.object.isRequired,
};

export default ParagraphQuoteCarousel;
