import React from "react";

import eventPageQuery from "./event.graphql";
import useNodeQuery from "@default/hooks/use-node-query";
import NodeWrapper from "@default/nodes/shared-components/node-wrapper";

import Appearances from "@default/nodes/shared-components/appearances/appearances";
import MetaInfos from "@default/js/participants/components/meta-infos";
import DeezerWidget from "@default/js/participants/components/deezer-widget";
import LookingFor from "@default/js/participants/components/looking-for";
import SocialLinks from "@default/js/participants/components/social-links";
import Related from "@default/js/participants/components/related/related";
import DetailPageIntro from "@default/nodes/shared-components/detail-page-intro";
import ParticipatingPersons from "@default/nodes/shared-components/participating-persons";
import ShowcaseActs from "@default/nodes/shared-components/participating-acts";
import LinkedEvents from "@default/teasers/event/components/linked-events";
import Sponsors from "@default/js/participants/components/sponsors";
import CheckForChildren from "@default/lib/check-for-children";
import Credit from "@default/nodes/shared-components/credit";
import MainPresenterTextInfo from "@default/nodes/shared-components/main-presenter-text-info";

const Event = () => {
  const { entity, loading, error } = useNodeQuery(eventPageQuery);

  return (
    <NodeWrapper entity={entity} loading={loading} error={error}>
      <DetailPageIntro content={entity} mode={"event"} />
      <div className="container max-width main-container">
        <div className="row infos">
          <div className={"col-16 col-lg-12 appearances-wrapper"}>
            <Appearances
              detailedMode={true}
              content={entity}
              type={"event"}
              showAddToCalendar={true}
              viewMode={"detail"}
              showEndDate={true}
            />
          </div>

          <div className={"col-16 col-lg-4 meta-info-wrapper"}>
            <MetaInfos content={entity} />
          </div>
        </div>

        <div className="row text-wrapper">
          <MainPresenterTextInfo content={entity} />

          {entity?.fieldText && (
            <div className="col-16 text-wrapper">
              <div
                className={"text headline-subhead"}
                dangerouslySetInnerHTML={{ __html: entity.fieldText }}
              />
            </div>
          )}

          <Credit content={entity} />
        </div>

        <div className="row with-gaps">
          <LinkedEvents content={entity} />

          <ParticipatingPersons content={entity} />

          <ShowcaseActs content={entity} />

          <CheckForChildren classes={"col-16 col-lg-8 left-col"}>
            <DeezerWidget content={entity} />
            <LookingFor content={entity} />
            <SocialLinks content={entity} />
          </CheckForChildren>

          <CheckForChildren classes={"col-16 col-lg-8 right-col"}>
            <Sponsors content={entity} />
          </CheckForChildren>
        </div>
      </div>
      <div className="related-container">
        <Related content={entity} type={"event"} />
      </div>
    </NodeWrapper>
  );
};

Event.propTypes = {};

export default Event;
