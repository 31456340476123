import React, { useState } from "react";
import PropTypes from "prop-types";
import Image from "../../../../image/image";
import Link from "../../../../link/link";
import { restHostBackend } from "@default/js/config";
import CustomAnimation from "@default/general-components/custom-animation";
import SpotifyButtonListen from "@default/js/participants/components/spotify-widget/spotify-button-listen";
import SpotifyButtonIdle from "@default/js/participants/components/spotify-widget/spotify-button-idle";
import LazyLoad from "react-lazyload";

const DossierElement = ({ content, active }) => {
  const [muted, setMuted] = useState(true);

  const imageData =
    content.fieldImage?.fieldMediaImage ||
    content.fieldReferencedNodes?.fieldImage?.fieldMediaImage;

  const title = content.fieldHeading || content.fieldReferencedNodes?.title;
  const text = content.fieldText || content.fieldReferencedNodes?.fieldText;

  const videoUrl =
    content.fieldVideoFile &&
    `${restHostBackend}/${content.fieldVideoFile?.mediaFileUrl?.path}`;

  const toggleMute = () => {
    setMuted(!muted);
  };

  const TeaserContent = () => {
    return (
      <>
        <LazyLoad offset={500}>
          {imageData && (
            <div className="main-image">
              <Image data={imageData} />
              {videoUrl && (
                <CustomAnimation type={"appear"} isVisible={active}>
                  <video
                    src={videoUrl}
                    autoPlay={true}
                    loop={true}
                    muted={muted}
                    playsInline={true}
                  />
                  <button
                    className="toggle-spotify"
                    onClick={() => toggleMute()}
                  >
                    <CustomAnimation isVisible={!muted} type={"appear"}>
                      <SpotifyButtonListen />
                    </CustomAnimation>
                    <CustomAnimation isVisible={muted} type={"appear"}>
                      <SpotifyButtonIdle />
                    </CustomAnimation>
                  </button>
                </CustomAnimation>
              )}
            </div>
          )}
        </LazyLoad>
        <div className="teaser-body">
          {title && <h3 className="headline body-m">{title}</h3>}
          {text && (
            <div
              className="text body-m"
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}
          {content.fieldDossierLink && (
            <>
              {content.fieldDossierLink.title ? (
                <span className="read-more">
                  {content.fieldDossierLink.title}
                </span>
              ) : (
                <span className="read-more">learn more</span>
              )}
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <article className="dossier-element">
      {content.fieldDossierLink ? (
        <Link link={content.fieldDossierLink}>
          <TeaserContent />
        </Link>
      ) : (
        <article>
          <TeaserContent />
        </article>
      )}
    </article>
  );
};

DossierElement.propTypes = {
  active: PropTypes.bool,
  content: PropTypes.shape({
    fieldImage: PropTypes.object,
    fieldText: PropTypes.string,
    fieldTitle: PropTypes.string,
    fieldReferencedNodes: PropTypes.shape({
      title: PropTypes.string,
      fieldText: PropTypes.string,
      fieldImage: PropTypes.shape({
        fieldMediaImage: PropTypes.shape({
          alt: PropTypes.string,
          title: PropTypes.string,
          style: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      }),
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
  }),
};

export default DossierElement;
