import React, { useState } from "react";
import ReactDOM from "react-dom";
import Settings from "@default/assets/ui/settings.svg";
import Filters from "../filters";
import useOverviewContext from "@default/paragraphs/overview/store/use-overview-context";
import { AnimatePresence } from "framer-motion";
import _ from "lodash";
import PropTypes from "prop-types";
import LockBodyScroll from "@default/lib/use-lock-body-scroll";
import classNames from "classnames";

const FilterControls = ({ children }) => {
  const [filterOpen, setFilterOpen] = useState(false);
  const { searchParams } = useOverviewContext();

  let activeFiltersAmount = 0;
  const ignoreKeys = ["weekday", "sort", "edition"];
  if (typeof window !== "undefined" && searchParams.entries()) {
    Array.from(searchParams.entries()).forEach(([key, value]) => {
      if (!ignoreKeys.includes(key) && !_.isEmpty(value)) {
        activeFiltersAmount++;
      }
    });
  }

  return (
    <>
      <button
        onClick={() => setFilterOpen((s) => !s)}
        className={classNames({
          "toggle-filter-overlay filter-control-element": true,
          active: filterOpen || activeFiltersAmount > 0,
        })}
      >
        <img src={Settings} alt={"Settings"} />
        <div
          className={classNames({
            "font-style-change": true,
            active: filterOpen || activeFiltersAmount > 0,
          })}
        >
          <span className="highlighted">
            {children}
            {!!activeFiltersAmount && <span>({activeFiltersAmount})</span>}
          </span>
          <span className="standard">
            {children}
            {!!activeFiltersAmount && <span>({activeFiltersAmount})</span>}
          </span>
        </div>
      </button>
      {typeof window !== "undefined" && (
        <>
          {ReactDOM.createPortal(
            <AnimatePresence>
              {filterOpen && (
                <>
                  <Filters onFilterClose={() => setFilterOpen(false)} />
                  <LockBodyScroll />
                </>
              )}
            </AnimatePresence>,
            document?.getElementById("modal-wrapper")
          )}
        </>
      )}
    </>
  );
};

FilterControls.propTypes = {
  children: PropTypes.node,
};

export default FilterControls;
