import React from "react";
import { FormattedMessage } from "react-intl";
import TeaserActShowcase from "@default/teasers/act/teaser-act-showcase";
import { sortByDate } from "@default/lib/sort-by-date";

const ShowcaseActs = ({ content }) => {
  //@todo: What happens when a showcase event has to dates with different Showcase-Acts? Does this even happen? By now this would lead to irritations.

  let showcaseArtistsFromAppearances = [];

  content?.fieldAppearances?.map((act) => {
    showcaseArtistsFromAppearances.push(...act?.fieldShowcaseActs);
  });

  showcaseArtistsFromAppearances = showcaseArtistsFromAppearances?.map(
    (appearance) => {
      return appearance;
    }
  );

  let showcaseArtists = [
    ...content?.fieldShowcaseArtists,
    ...showcaseArtistsFromAppearances,
  ];

  if (showcaseArtists?.length >= 1) {
    return (
      <div className="showcase-acts">
        <div className="container">
          <div className="row">
            <div className="col-16 label headline-m">
              <FormattedMessage id={"acts"} />
            </div>
            {showcaseArtists.map((act, actId) => (
              <React.Fragment key={actId}>
                <TeaserActShowcase
                  item={act}
                  showcaseEvent={content}
                  pagerFullPage={false}
                />
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    );
  }
};

export default ShowcaseActs;
