import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import { useDispatch } from "react-redux";

import LoadingIndicator from "../../../../loading-indicator";
import TeaserNews, {
  teaserNewsPropTypes,
} from "../../../../teaser-base/news/teaser-news";
import TeaserPerson, {
  teaserPersonPropTypes,
} from "../../../../teaser-base/person/teaser-person";
import { pagerFullPagesAction } from "../../../../../app-actions";
import { teaserEventPropTypes } from "../../../../teaser-base/event/teaser-event";
import TeaserGeneral, {
  teaserGeneralPropTypes,
} from "../../../../teaser-base/general/teaser-general";
import { teaserProjectPropTypes } from "../../../../teaser-base/project/teaser-project";
import useTeaserQuery from "./use-teaser-query";
import TeaserParticipantSwitch from "../../../../teaser-base/teaser-participant-switch";
import ActsCarousel from "../acts/acts-carousel";

const ComponentTeaserListCarousel = (props) => {
  const dispatch = useDispatch();
  const { nodes, loading } = useTeaserQuery(props.manualNodes, {
      ...props,
      limit: 15,
    }),
    [slideIndex, setSlideIndex] = useState(0);

  const sliderSettings = {
    adaptiveHeight: false,
    arrows: true,
    centerMode: false,
    dots: false,
    infinite: false,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove: true,
    beforeChange: (oldIndex, newIndex) => {
      setTimeout(() => {
        setSlideIndex(newIndex);
      }, 10);
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.25,
          touchMove: true,
        },
      },
      {
        breakpoint: 786,
        settings: {
          slidesToShow: 1.66,
          touchMove: true,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1.25,
          touchMove: true,
        },
      },
    ],
  };

  const pushPagerFullPageConfig = () => {
    if (props.pagerFullPage && nodes) {
      const pagerFullPagesConfig = {
        id: props.id,
        items:
          props.mode === "manual"
            ? props.manualNodes.map((item) => item.entity)
            : nodes.items,
        overviewLink: props.pagerFullPageOverviewLink,
      };

      dispatch(pagerFullPagesAction(pagerFullPagesConfig));
    }
  };

  useEffect(() => {
    pushPagerFullPageConfig();
  }, [nodes]);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (nodes.length >= 4 && props.type !== "acts") {
    return (
      <Slider {...sliderSettings}>
        {nodes.map((item, index) => (
          <React.Fragment key={index}>
            {(() => {
              switch (item.entityBundle) {
                case "news":
                  return (
                    <TeaserNews
                      item={item}
                      pagerFullPage={props.pagerFullPage}
                      activeBefore={
                        slideIndex !== 0 && slideIndex - 1 === index
                      }
                    />
                  );
                case "person":
                  return (
                    <TeaserPerson
                      item={item}
                      pagerFullPage={props.pagerFullPage}
                      activeBefore={
                        slideIndex !== 0 && slideIndex - 1 === index
                      }
                    />
                  );
                case "landingpage":
                  return (
                    <TeaserGeneral
                      item={item}
                      pagerFullPage={props.pagerFullPage}
                      activeBefore={
                        slideIndex !== 0 && slideIndex - 1 === index
                      }
                    />
                  );
                case "participant":
                  return (
                    <TeaserParticipantSwitch
                      item={item}
                      pagerFullPage={props.pagerFullPage}
                    />
                  );
                default:
                  return null;
              }
            })()}
          </React.Fragment>
        ))}
      </Slider>
    );
  }

  if (nodes.length >= 4 && props.type === "acts") {
    return <ActsCarousel nodes={nodes} />;
  }
};

ComponentTeaserListCarousel.propTypes = {
  limit: PropTypes.number.isRequired,
  manualNodes: PropTypes.arrayOf(
    PropTypes.oneOfType([
      teaserNewsPropTypes,
      teaserEventPropTypes,
      teaserPersonPropTypes,
      teaserGeneralPropTypes,
      teaserProjectPropTypes,
    ])
  ),
  id: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(["auto", "manual"]),
  type: PropTypes.oneOf(["news", "person", "event", "all", "acts"]),
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  pagerFullPageOverviewLink: PropTypes.object,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default ComponentTeaserListCarousel;
