import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Image from "../../image/image";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import EditButton from "../../../backend/edit-button";
import { pagerFullPageContextAction } from "../../../app-actions";
import Tags from "../../tags/tags";
import FieldTime from "../../date-time/field-time";
import CustomHyphenation from "@default/general-components/custom-hyphenation";
import LazyLoad from "react-lazyload";

const TeaserNews = ({ item, pagerFullPage = false, activeBefore }) => {
  const adminApp = useSelector((reduxStore) => reduxStore.appStore.adminApp);
  const dispatch = useDispatch();
  const location = useLocation();
  const newsTeaser = useRef();

  useEffect(() => {
    if (newsTeaser.current) {
      newsTeaser.current.style.setProperty(
        "--ratio-width",
        item?.fieldImage?.fieldMediaImage.style.width
      );
      newsTeaser.current.style.setProperty(
        "--ratio-height",
        item?.fieldImage?.fieldMediaImage.style.height
      );
    }
  }, []);

  let link = item.url ? item.url.path : item.path.alias;

  if (item.fieldExternalLink) {
    link = item.fieldExternalLink?.uri?.path;
  }

  return (
    <article
      className="node node-teaser teaser-news"
      data-active-before={activeBefore}
      data-sponsored={item.fieldSponsored}
      ref={newsTeaser}
    >
      <EditButton
        adminApp={adminApp}
        entityId={item.id}
        destinationRoute={location.pathname}
      />
      <div className="flex-wrap">
        {item.fieldImage && (
          <div
            className={`image-wrapper ${
              item.fieldImage.fieldMediaImage.style.width >
              item.fieldImage.fieldMediaImage.style.height
                ? "horizontal"
                : "vertical"
            }`}
          >
            <div className="caption-positioner">
              <LazyLoad offset={500}>
                <Image
                  data={item.fieldImage.fieldMediaImage}
                  nodeTitle={item.title}
                  credit={item.fieldImage.fieldCredits}
                />
              </LazyLoad>
            </div>
          </div>
        )}
        <Link
          className="flex-wrap"
          to={link}
          tabIndex={0}
          role={"button"}
          aria-label={"open page"}
          target={item.fieldExternalLink ? "_blank" : "_self"}
        >
          <div className="news-info">
            <div className="meta">
              {item.publishedAt && (
                <div className="published-on">
                  <FieldTime
                    timestamp={false}
                    date={item.publishedAt}
                    format={"D. MMMM"}
                  />
                </div>
              )}
              <Tags tags={item.fieldTags} />
              {item.fieldSponsored && (
                <div className="tags-wrapper">
                  <div className="tag">[Sponsored News]</div>
                </div>
              )}
            </div>
            <div className="text-content">
              <h3 className="headline">
                <CustomHyphenation length={10}>{item.title}</CustomHyphenation>
              </h3>
              {item.fieldText && (
                <div
                  className="teaser text body-m"
                  dangerouslySetInnerHTML={{ __html: item.fieldText }}
                />
              )}
            </div>
          </div>
          <span className="read-more">learn more</span>
        </Link>
      </div>
    </article>
  );
};

export const teaserNewsPropTypes = PropTypes.shape({
  id: PropTypes.string,
  activeBefore: PropTypes.bool,
  title: PropTypes.string,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  publishedAtRawField: PropTypes.shape({
    first: PropTypes.shape({
      value: PropTypes.string,
      publishedAtOrNow: PropTypes.string,
    }),
  }),
  fieldTags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  fieldText: PropTypes.string,
  fieldImage: PropTypes.shape({
    fieldMediaImage: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      style: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
});

TeaserNews.propTypes = {
  item: teaserNewsPropTypes,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default TeaserNews;
