import React from "react";
import PropTypes from "prop-types";
import Video from "@default/paragraphs/video/components/video";
import Link from "../../../../link/link";

const Content = ({ content }) => {
  return (
    <>
      {content.fieldHeading && (
        <h3 className="headline headline-s">{content.fieldHeading}</h3>
      )}
      {content.fieldTeasertext && (
        <div
          className="text teasertext body-s"
          dangerouslySetInnerHTML={{
            __html: content.fieldTeasertext,
          }}
        />
      )}
    </>
  );
};

const VideoSlide = ({ content, active }) => {
  return (
    <article className="video-slide">
      {content.fieldVideo && content.fieldImage && (
        <Video
          content={content.fieldVideo}
          customThumbnail={content.fieldImage.fieldMediaImage}
        />
      )}
      {content.fieldLink ? (
        <Link link={content.fieldLink}>
          <Content content={content} />
          <span className="action-element body-s">
            {content.fieldLink.title ? (
              <>{content.fieldLink.title}</>
            ) : (
              <>Learn more</>
            )}
          </span>
        </Link>
      ) : (
        <Content content={content} />
      )}
    </article>
  );
};

VideoSlide.propTypes = {
  active: PropTypes.bool,
  content: PropTypes.shape({
    fieldImage: PropTypes.object,
    fieldText: PropTypes.string,
    fieldTitle: PropTypes.string,
    fieldReferencedNodes: PropTypes.shape({
      title: PropTypes.string,
      fieldText: PropTypes.string,
      fieldImage: PropTypes.shape({
        fieldMediaImage: PropTypes.shape({
          alt: PropTypes.string,
          title: PropTypes.string,
          style: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      }),
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
  }),
};

export default VideoSlide;
