import React from "react";

import ContentCloud from "@default/paragraphs/content-cloud/paragraph-content-cloud";
import ParagraphAdvertisingBanner from "@default/paragraphs/advertising-banner/paragraph-advertising-banner";
import ParagraphAudio from "@default/paragraphs/audio/paragraph-audio";
import ParagraphCalendar from "@default/paragraphs/calendar/paragraph-calendar";
import ParagraphCalendarMonth from "@default/paragraphs/calendar-month/paragraph-calendar-month";
import ParagraphCallToAction from "@default/paragraphs/call-to-action/paragraph-call-to-action";
import ParagraphClickSuggestions from "@default/paragraphs/click-suggestions/paragraph-click-suggestions";
import ParagraphClickSuggestionsTopics from "@default/paragraphs/click-suggestions-topics/paragraph-click-suggestions-topics";
import ParagraphCollapse from "@default/paragraphs/collapse/paragraph-collapse";
import ParagraphContact from "@default/paragraphs/contact/paragraph-contact";
import ParagraphDossier from "@default/paragraphs/dossier/paragraph-dossier";
import ParagraphSimpleDownload from "@default/paragraphs/simple-download/paragraph-simple-download";
import ParagraphDownloadOverview from "@default/paragraphs/download-overview/paragraph-download-overview";
import ParagraphEventInformation from "@default/paragraphs/event-information/paragraph-event-information";
import ParagraphExtendedTeaserOverview from "@default/paragraphs/extended-teaser-list/paragraph-extended-teaser-overview";
import ParagraphExtendedTeaserOverviewProjects from "@default/paragraphs/extended-teaser-list/projects/paragraph-extended-teaser-overview-projects";
import ParagraphFactsAndFigures from "@default/paragraphs/facts-and-figures/paragraph-facts-and-figures";
import ParagraphForm from "@default/paragraphs/webform/paragraph-form";
import ParagraphHeroText from "@default/paragraphs/hero-text/paragraph-hero-text";
import ParagraphImage from "@default/paragraphs/image/paragraph-image";
import ParagraphList from "@default/paragraphs/list/paragraph-list";
import ParagraphLogosPartners from "@default/paragraphs/logos-partners/logos-partners";
import ParagraphMediaGallery from "@default/paragraphs/media-gallery/paragraph-media-gallery";
import ParagraphNewsletterRegistration from "@default/paragraphs/newsletter-registration/paragraph-newsletter-registration";
import ParagraphOverview from "@default/paragraphs/overview/paragraph-overview";
import ParagraphPageTitle from "@default/paragraphs/page-title/paragraph-page-title";
import ParagraphPersonInformation from "@default/paragraphs/person-information/paragraph-person-information";
import ParagraphQuote from "@default/paragraphs/quote/paragraph-quote";
import ParagraphQuoteCarousel from "@default/paragraphs/quote-carousel/paragraph-quote-carousel";
import ParagraphSectionheading from "@default/paragraphs/section-heading/paragraph-sectionheading";
import ParagraphTeaserImage from "@default/paragraphs/teaser-image/paragraph-teaser-image";
import ParagraphTeaserList from "@default/paragraphs/teaser-list/paragraph-teaser-list";
import ParagraphTeaserListEvents from "@default/paragraphs/teaser-list/events/paragraph-teaser-list-events";
import ParagraphTeaserSlider from "@default/paragraphs/teaser-slider/paragraph-teaser-slider";
import ParagraphText from "@default/paragraphs/text/paragraph-text";
import ParagraphTextAndSpeech from "@default/paragraphs/text-and-speech/paragraph-text-and-speech";
import ParagraphTimeline from "@default/paragraphs/timeline/paragraph-timeline";
import ParagraphVideo from "@default/paragraphs/video/paragraph-video";
import ParagraphVideoSlider from "@default/paragraphs/video-slider/paragraph-video-slider";

// Microsites
// RBX-Company
import ParagraphBrandsOverview from "./../../microsites/rbx-company/general-components/paragraphs/brands-overview/paragraph-brands-overview";

import Eforkbot from "../../eforkbot/eforkbot";
import { PropTypes } from "prop-types";

const ParagraphSwitch = ({ paragraphItem, nodeContent, viewMode }) => {
  let properties = {
    content: paragraphItem,
    viewMode: viewMode,
  };

  switch (paragraphItem?.entityBundle) {
    case "content_cloud":
      return <ContentCloud {...properties} />;
    case "text_and_speech":
      return <ParagraphTextAndSpeech {...properties} />;
    case "audio":
      return <ParagraphAudio {...properties} />;
    case "video":
      return <ParagraphVideo {...properties} />;
    case "hero_text":
      return <ParagraphHeroText {...properties} />;
    case "click_suggestions":
      return <ParagraphClickSuggestions {...properties} />;
    case "click_suggestions_topics":
      return <ParagraphClickSuggestionsTopics {...properties} />;
    case "teaser_list_news":
    case "teaser_list_persons":
    case "teaser_list_pages":
    case "teaser_list_acts":
      return <ParagraphTeaserList {...properties} />;
    case "teaser_list_events":
      return <ParagraphTeaserListEvents {...properties} />;
    case "teaser_image_text":
      return <ParagraphTeaserImage {...properties} />;
    case "section_heading":
      return <ParagraphSectionheading {...properties} />;
    case "text":
      return <ParagraphText {...properties} />;
    case "media_gallery":
      return <ParagraphMediaGallery {...properties} />;
    case "image":
      return <ParagraphImage {...properties} />;
    case "facts_and_figures":
      return <ParagraphFactsAndFigures {...properties} />;
    case "teaser_slider":
      return <ParagraphTeaserSlider {...properties} />;
    case "form":
      return <ParagraphForm {...properties} />;
    case "newsletter_registration":
      return <ParagraphNewsletterRegistration {...properties} />;
    case "collapse":
      return <ParagraphCollapse {...properties} />;
    case "chatbot":
      return <Eforkbot />;
    case "dossier":
      return <ParagraphDossier {...properties} />;
    case "page_title":
      return <ParagraphPageTitle {...properties} />;
    case "call_to_action":
      return <ParagraphCallToAction {...properties} />;
    case "erweiterte_teaseruebersicht":
    case "teaseruebersicht_events":
    case "teaseruebersicht_personen": {
      const nodes = {};

      if (paragraphItem.fieldManuelleSortierung) {
        nodes.loading = false;
        nodes.nodeQuery = {};
        nodes.nodeQuery.entities = paragraphItem.fieldBeitraege.map(
          (node) => node.entity
        );

        return (
          <ParagraphExtendedTeaserOverview {...properties} nodes={nodes} />
        );
      }

      return <ParagraphExtendedTeaserOverview {...properties} />;
    }
    case "teaseruebersicht_projekte": {
      const nodes = {};

      if (paragraphItem.fieldManuelleSortierung) {
        nodes.loading = false;
        nodes.nodeQuery = {};
        nodes.nodeQuery.entities = paragraphItem.fieldBeitraege.map(
          (node) => node.entity
        );

        return (
          <ParagraphExtendedTeaserOverviewProjects
            {...properties}
            nodes={nodes}
          />
        );
      }

      return <ParagraphExtendedTeaserOverviewProjects {...properties} />;
    }
    case "event_information":
      return (
        <ParagraphEventInformation {...properties} nodeContent={nodeContent} />
      );
    case "person_information":
      return (
        <ParagraphPersonInformation {...properties} nodeContent={nodeContent} />
      );
    case "simple_download":
      return <ParagraphSimpleDownload {...properties} />;
    case "download_overview":
      return <ParagraphDownloadOverview {...properties} />;
    case "quote":
      return <ParagraphQuote {...properties} />;
    case "quote_carousel":
      return <ParagraphQuoteCarousel {...properties} />;
    case "timeline":
      return <ParagraphTimeline {...properties} />;
    case "contact":
      return <ParagraphContact {...properties} />;
    case "list":
      return <ParagraphList {...properties} />;
    case "calendar_month":
      return <ParagraphCalendarMonth {...properties} />;
    case "calendar":
      return <ParagraphCalendar {...properties} />;
    case "logos_partners":
      return <ParagraphLogosPartners {...properties} />;
    case "advertising_banner":
      return <ParagraphAdvertisingBanner {...properties} />;
    case "overview_news":
    case "overview_persons":
    case "overview_projects":
    case "overview_events":
    case "overview_venues":
    case "overview_acts":
    case "overview_speakers":
    case "overview_timetable":
      return <ParagraphOverview {...properties} />;
    case "brands_overview":
      return <ParagraphBrandsOverview {...properties} />;
    case "video_slider":
      return <ParagraphVideoSlider {...properties} />;
    default:
      return null;
  }
};

ParagraphSwitch.propTypes = {
  paragraphItem: PropTypes.object,
  nodeContent: PropTypes.object,
  viewMode: PropTypes.string,
};

export default ParagraphSwitch;
