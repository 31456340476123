import React, { useEffect, useState } from "react";
import useOverviewContext from "@default/paragraphs/overview/store/use-overview-context";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import scrollTo from "@default/lib/scrollto-with-callback";
import { useDispatch, useSelector } from "react-redux";
import {
  panicScrollBlockAction,
  preventJumpToCurrentTimeAction,
} from "@default/js/app-actions";
import moment from "moment";
import { mappedWeekday } from "./mapped-weekdays";
import { festivalDayOffset } from "@default/js/lib/festival-day-offset";

/**
 * Component for the day controls.
 * This component will display buttons for each day of the week.
 * @returns {JSX.Element} The DayControls component.
 */
const DayControls = () => {
  const { searchParams, setSearchParams, filterForCurrentDay } =
    useOverviewContext();
  const dispatch = useDispatch();
  const [preventFilterForCurrentDay, setPreventFilterForCurrentDay] =
    useState(false);
  const [offset, offsetUnit] = festivalDayOffset;

  /* If Festival is currently happening and no search params are set, this Function sets the current Date "on Load".
   *  We need to ask how the filter is triggered, because if a user clicks and disables a filter, the automatic "filterForCurrentDay" should not be triggered.
   */
  useEffect(() => {
    if (filterForCurrentDay && !preventFilterForCurrentDay) {
      // Get Weekday, but using Festival Offset
      const dayWithFestivalOffset = moment().add(offset, offsetUnit);

      // Map Weekday from moments name to param name.
      setCurrentDayFilter(mappedWeekday(dayWithFestivalOffset), "currentDay");

      // Tell the Context that jumping to current Time can now be possible again
      dispatch(preventJumpToCurrentTimeAction(false));
    }
  }, [filterForCurrentDay]);

  const setCurrentDayFilter = (day, type) => {
    setSearchParams((_searchParams) => {
      // reset the filter if the same button is clicked again.
      if (searchParams.get("weekday") === day) {
        _searchParams.delete("weekday");
        setPreventFilterForCurrentDay(true);
      } else {
        _searchParams.set("weekday", day);
        setPreventFilterForCurrentDay(false);
      }

      dispatch(panicScrollBlockAction(true));

      scrollTo({
        top: document.querySelector(".paragraph-extended-teaser-view")
          ?.offsetTop,
        behavior: "smooth",
      }).then(() => {
        dispatch(panicScrollBlockAction(false));
      });

      // return the new values
      return _searchParams;
    });
  };

  const buttonClickHandler = (day) => {
    setCurrentDayFilter(day, "click");
  };

  const weekdays = ["wed", "thu", "fri", "sat"];

  const currentIndex = weekdays.indexOf(searchParams.get("weekday"));

  return (
    <div
      className="main-control group-switch filter-control-element day-controls"
      style={{
        "--animation-children": weekdays.length,
        "--current-index": currentIndex < 0 ? undefined : currentIndex,
        "--opacity": currentIndex < 0 ? 0 : 1,
      }}
    >
      <small className="d-block label sr-only">Sort By</small>
      {weekdays.map((day) => (
        <button
          key={day}
          className={classNames({
            "font-style-change": true,
            active: searchParams.get("weekday") === day,
          })}
          onClick={() => buttonClickHandler(day)}
        >
          <span className="highlighted">
            <FormattedMessage id={`weekday.short.${day}`} />
          </span>
          <span className="standard">
            <FormattedMessage id={`weekday.short.${day}`} />
          </span>
        </button>
      ))}
    </div>
  );
};

DayControls.propTypes = {};

export default DayControls;
