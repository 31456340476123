import React, { useEffect, useState } from "react";

// Teaser Participant Masks
import teaserParticipantMask1 from "@default/assets/masks/mask-1.svg";
import teaserParticipantMask2 from "@default/assets/masks/mask-2.svg";
import teaserParticipantMask3 from "@default/assets/masks/mask-3.svg";
import teaserParticipantMask4 from "@default/assets/masks/mask-4.svg";
import teaserParticipantMask5 from "@default/assets/masks/mask-5.svg";

// Act Detail Masks
import actDetailMask1 from "@default/assets/masks/act-detail-mask-1.svg";
import actDetailMask2 from "@default/assets/masks/act-detail-mask-2.svg";
import actDetailMask3 from "@default/assets/masks/act-detail-mask-3.svg";

const ColorMask = ({
  index,
  forceMask = false,
  className,
  type,
  useCssMask = false,
}) => {
  const [mask, setMask] = useState("");

  // Takes one of the possible color masks and renders it. Selection can be forced via "forceMask" or it is automatically generated by index.
  const teaserParticipantMasks = [
    teaserParticipantMask1,
    teaserParticipantMask2,
    teaserParticipantMask3,
    teaserParticipantMask4,
    teaserParticipantMask5,
  ];

  const actDetailMasks = [actDetailMask1, actDetailMask2, actDetailMask3];

  useEffect(() => {
    if (type === "teaser-participant" && index) {
      setMask(
        forceMask
          ? teaserParticipantMasks[forceMask]
          : teaserParticipantMasks[index % teaserParticipantMasks.length]
      );
    }

    if (type === "act-detail") {
      setMask(
        forceMask
          ? actDetailMasks[forceMask]
          : actDetailMasks[Math.floor(Math.random() * actDetailMasks.length)]
      );
    }
  }, []);

  if (useCssMask) {
    return <div className={`color-element ${className}`} />;
  } else {
    return (
      <img
        src={mask}
        alt="Color masked image"
        className={`color-element ${className}`}
      />
    );
  }
};

export default ColorMask;
