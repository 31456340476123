import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import NodeWrapper from "../shared-components/node-wrapper";
import useNodeQuery from "@default/hooks/use-node-query";
import landingPageQuery from "./landingpage.graphql";
import PagerFullPage from "@default/js/general-components/pager-full-page";
import ContentBase from "@default/general-components/content-base/content-base";
import { overwriteFooterAction } from "@default/js/app-actions";

const Landingpage = () => {
  const dispatch = useDispatch();

  /**
   * Hook to get information from Redux Store
   */
  const pagerConfig = useSelector(
    (reduxStore) => reduxStore.appStore.pagerFullPages
  );
  const pagerFullPageContextId = useSelector(
    (reduxStore) => reduxStore.appStore.pagerFullPageContextId
  );

  const { entity, loading, error } = useNodeQuery(landingPageQuery);

  // Push used Custom Footer to Props if Used in a Landingpage
  const overwriteFooter = () => {
    if (entity && !!entity.fieldOverwriteFooter) {
      dispatch(overwriteFooterAction(entity.fieldOverwriteFooter));
    } else {
      dispatch(overwriteFooterAction(false));
    }
  };

  useEffect(() => {
    overwriteFooter();
  }, [entity?.fieldOverwriteFooter]);

  return (
    <NodeWrapper entity={entity} loading={loading} error={error}>
      {entity?.fieldModules && (
        <ContentBase content={entity.fieldModules.list} viewMode={"standard"} />
      )}

      {pagerFullPageContextId &&
        pagerConfig.filter((config) => config.id === pagerFullPageContextId)
          .length > 0 && (
          <PagerFullPage
            config={
              pagerConfig.filter(
                (config) => config.id === pagerFullPageContextId
              )[0]
            }
            currentId={entity.entityId}
          />
        )}
    </NodeWrapper>
  );
};

Landingpage.propTypes = {};

export default Landingpage;
