import React, { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import LoadingIndicator from "@default/general-components/loading-indicator";
import useMediaGalleryContext from "./../media-gallery/store/use-media-gallery-context";
import VideoSlide from "./components/video-slide";

const VideoSlider = ({ content }) => {
  const swiperRefLocal = useRef();
  const {
    Swiper,
    SwiperSlide,
    Navigation,
    Pagination,
    isCssLoaded,
    Autoplay,
    setSwiperRef,
    setActiveIndex,
    activeIndex,
  } = useMediaGalleryContext();

  if (!Swiper || !SwiperSlide || !Navigation || !Pagination || !isCssLoaded) {
    return <LoadingIndicator fullPage={true} />; // Or some other loading indicator
  }

  if (swiperRefLocal?.current && setSwiperRef) {
    setSwiperRef(swiperRefLocal);
  }

  return (
    <section
      className={classNames({
        "paragraph paragraph-video-slider": true,
      })}
      data-darkmode={content.fieldDarkMode}
    >
      <div className="container-fluid">
        <div className="col-16">
          <Swiper
            spaceBetween={18}
            slidesPerView={2.2}
            loop={false}
            navigation={{ enabled: true }}
            // Save roccect active Index in Style. RealIndex recognizes looped slides.
            onRealIndexChange={(element) => {
              setActiveIndex(element.realIndex);
            }}
            modules={[Pagination, Navigation, Autoplay]}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              0: {
                slidesPerView: 1.2,
              },
              768: {
                slidesPerView: 2.2,
              },
            }}
            ref={swiperRefLocal}
          >
            {content.fieldVideoSlides?.map((item, index) => (
              <SwiperSlide key={index}>
                {({ isActive }) => {
                  return <VideoSlide content={item} isActive={isActive} />;
                }}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

VideoSlider.propTypes = {
  viewMode: PropTypes.string,
  content: PropTypes.shape({
    fieldMultimediaRawField: PropTypes.shape({
      list: PropTypes.arrayOf(
        PropTypes.shape({
          customCaption: PropTypes.string,
          defaultCaption: PropTypes.bool,
          entity: PropTypes.shape({
            fieldCaption: PropTypes.string,
            fieldCredits: PropTypes.string,
            fieldMediaImage: PropTypes.shape({
              alt: PropTypes.string,
              title: PropTypes.string,
              style: PropTypes.shape({
                url: PropTypes.string,
                width: PropTypes.number,
                height: PropTypes.number,
              }),
            }),
          }),
        })
      ),
    }),
  }),
};

export default VideoSlider;
