import React from "react";
import useOverviewContext from "../store/use-overview-context";
import _ from "lodash";
import TeaserNews from "@default/teasers/news/teaser-news";
import moment from "moment-timezone";
import { FormattedMessage } from "react-intl";
import Controls from "../components/main-controls";
import Scrollspy from "../components/main-controls/scrollspy";

const OverviewNews = () => {
  const { nodes, fieldNewsType } = useOverviewContext();

  const groupedNodes = _.groupBy(nodes, (node) =>
    moment(node.publishedAt).format("YYYY")
  );

  if (groupedNodes) {
    return (
      <div className="news-overview container no-padding">
        <Controls>
          <div className="col scrollspy">
            <Scrollspy type="native" groupedNodes={groupedNodes} />
          </div>
        </Controls>
        <div className="news-wrapper container-fluid">
          {!!Object.entries(groupedNodes).length &&
            Object.entries(groupedNodes).map(([key, group]) => (
              <div className="year-wrapper row" id={key} key={key}>
                <div className="col-16 label headline-xl">
                  <FormattedMessage id={`archive.headline.${fieldNewsType}`} />{" "}
                  {key}
                </div>
                {group.map((node) => (
                  <div className="col-16 col-lg-custom" key={node.id}>
                    <TeaserNews item={node} pagerFullPage={false} />
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>
    );
  }
};

export default OverviewNews;
