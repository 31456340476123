import React, { Component } from "react";
import PropTypes from "prop-types";

import TeaserNews from "./news/teaser-news";
import TeaserNewsTimeline from "./news/teaser-news-timeline";
import TeaserPerson from "./person/teaser-person";
import TeaserNewsTimelineList from "./news/teaser-news-timeline-list";
import TeaserPersonContact from "./person/teaser-person-contact";
import TeaserEventEinzelDoppel from "./event/teaser-event-einzel-doppel";
import TeaserEvent from "./event/teaser-event";
import TeaserEventTeaserlist from "./event/teaser-event-teaserlist";
import TeaserGeneral from "./general/teaser-general";
import TeaserPersonOverview from "./person/teaser-person-overview";
import TeaserPersonOverlay from "./person/teaser-person-overlay";
import TeaserProject from "./project/teaser-project";
import TeaserVenue from "./venue/teaser-venue";
import TeaserAppearanceTimetable from "@default/teasers/appearance/teaser-appearance-timetable";

const TeaserBase = (props) => {
  switch (props.item.entityBundle) {
    case "news":
      if (props.viewMode === "timeline") {
        return (
          <TeaserNewsTimeline
            item={props.item}
            pagerFullPage={props.pagerFullPage}
          />
        );
      }

      if (props.viewMode === "timeline-list") {
        return (
          <TeaserNewsTimelineList
            item={props.item}
            pagerFullPage={props.pagerFullPage}
          />
        );
      }

      return (
        <TeaserNews item={props.item} pagerFullPage={props.pagerFullPage} />
      );

    case "person":
      if (props.viewMode === "contact") {
        return (
          <TeaserPersonContact
            showImage={props.showImage}
            item={props.item}
            pagerFullPage={props.pagerFullPage}
          />
        );
      }
      if (props.viewMode === "overview") {
        return (
          <TeaserPersonContact
            item={props.item}
            pagerFullPage={props.pagerFullPage}
          />
        );
      }
      if (props.viewMode === "overlay") {
        return (
          <TeaserPersonOverlay
            item={props.item}
            pagerFullPage={props.pagerFullPage}
          />
        );
      }
      return (
        <TeaserPerson item={props.item} pagerFullPage={props.pagerFullPage} />
      );

    case "event":
      if (props.viewMode === "doppel-einzel") {
        return (
          <TeaserEventEinzelDoppel
            item={props.item}
            pagerFullPage={props.pagerFullPage}
            single={props.single}
          />
        );
      }
      if (props.viewMode === "teaserlist") {
        return (
          <TeaserEventTeaserlist
            item={props.item}
            pagerFullPage={props.pagerFullPage}
            single={props.single}
          />
        );
      }
      return (
        <TeaserAppearanceTimetable
          item={props.item}
          pagerFullPage={props.pagerFullPage}
          updateUrl={true}
        />
      );

    case "project":
      if (props.viewMode === "project") {
        return (
          <TeaserProject
            item={props.item}
            pagerFullPage={props.pagerFullPage}
            single={props.single}
          />
        );
      } else {
        return (
          <TeaserGeneral
            item={props.item}
            pagerFullPage={props.pagerFullPage}
          />
        );
      }

    case "landingpage":
      return (
        <TeaserGeneral item={props.item} pagerFullPage={props.pagerFullPage} />
      );

    case "venue":
      return (
        <TeaserVenue item={props.item} pagerFullPage={props.pagerFullPage} />
      );

    default:
      return null;
  }
};

TeaserBase.propTypes = {
  item: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.bool,
  showImage: PropTypes.bool,
  single: PropTypes.bool,
  viewMode: PropTypes.string,
};

export default TeaserBase;
