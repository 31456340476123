import React from "react";
import LoadingIndicator from "../general-components/loading-indicator";
import useConfigQuery from "../hooks/use-config-query/use-config-query";
import Error from "../system/error";
import { useDispatch, useSelector } from "react-redux";
import Footer from "@default/js/footer/footer";
import RBXFooter from "@default/js/microsites/rbx-company/footer/rbx-footer";

const PageFooter = () => {
  const microSite = useSelector((reduxStore) => reduxStore.appStore.microSite);
  const overwriteFooter = useSelector(
    (reduxStore) => reduxStore.appStore.overwriteFooter
  );
  const currentLanguage = useSelector(
    (reduxStore) => reduxStore.i18n.currentLanguage
  );

  let configs = ["footer", "social_media"];

  if (microSite === "rbx-company") {
    configs = ["footer_rbx", "social_media_rbx"];
  }

  const {
    data: footerData,
    loading,
    error,
  } = useConfigQuery(configs, currentLanguage);

  if (loading) {
    return <LoadingIndicator />;
  }
  if (error) {
    return <Error error={error} />;
  }

  /**
   * The footer and social_media configs are requested at the same time so
   * they will be stored in an array called "items" (see useConfigQuery).
   */

  let [footerConfig, socialMediaConfig] = footerData?.items;

  return (
    <footer id="page-footer">
      {microSite === "rbx-company" ? (
        <RBXFooter
          footerConfig={footerConfig}
          socialMediaConfig={socialMediaConfig}
        />
      ) : (
        <Footer
          footerConfig={overwriteFooter ? overwriteFooter : footerConfig}
          socialMediaConfig={
            overwriteFooter ? overwriteFooter : socialMediaConfig
          }
        />
      )}
    </footer>
  );
};

PageFooter.propTypes = {};

export default PageFooter;
