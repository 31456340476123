import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { ErrorMessage, useFormikContext } from "formik";
import classNames from "classnames";

import { states } from "../states";
import { getValueFromStringObjPath } from "../webform-elements";

const SelectField = ({
  item,
  compositeIndex,
  compositeParent,
  defaultMenuIsOpen,
}) => {
  const { values, setFieldValue, handleBlur } = useFormikContext();

  const { invisible, visible, enabled, disabled, optional, required } = states(
    item["#states"],
    values
  );

  const value = compositeParent
    ? typeof compositeIndex !== "undefined"
      ? getValueFromStringObjPath(values, compositeParent)[compositeIndex][
          item.id
        ]
      : getValueFromStringObjPath(values, compositeParent)[item.id]
    : values[item.id];

  const identifier = compositeParent
    ? typeof compositeIndex !== "undefined"
      ? `${compositeParent}[${compositeIndex}][${item.id}]`
      : `${compositeParent}[${item.id}]`
    : item.id;

  const options = Object.keys(item["#options"]).map((key) => ({
    value: key,
    label: item["#options"][key],
  }));

  const handleChange = (selectedOption) => {
    const selectedValue = item["#multiple"]
      ? selectedOption.map((option) => option.value)
      : selectedOption?.value || "";
    setFieldValue(identifier, selectedValue);
  };

  return (
    <div
      className={classNames("form-group cc-select", {
        hidden: invisible || !visible,
      })}
      style={item["#flex"] ? { flex: item["#flex"] } : {}}
    >
      <label htmlFor={identifier} className="form-label">
        {item["#title"]}
        {(!!item["#required"] || required) && !optional && visible && (
          <span className="required">*</span>
        )}
      </label>

      <Select
        id={identifier}
        name={identifier}
        options={options}
        value={
          item["#multiple"]
            ? options.filter((option) => value?.includes(option.value))
            : options.find((option) => option.value === value) || null
        }
        onChange={handleChange}
        onBlur={handleBlur}
        isMulti={!!item["#multiple"]}
        isDisabled={!enabled || disabled}
        placeholder={item["#empty_option"] || "- Please select -"}
        classNamePrefix="cc-select"
        defaultMenuIsOpen={defaultMenuIsOpen}
        hideSelectedOptions={false}
      />

      <ErrorMessage
        role="region"
        aria-live="polite"
        component="span"
        name={item.id}
        className="error-message"
      />

      {!!item["#description"] && (
        <small className="form-description text-muted form-text">
          {item["#description"]}
        </small>
      )}
    </div>
  );
};

SelectField.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    "#states": PropTypes.object,
    "#flex": PropTypes.number,
    "#title": PropTypes.string,
    "#required": PropTypes.bool,
    "#description": PropTypes.string,
    "#multiple": PropTypes.bool,
    "#options": PropTypes.objectOf(PropTypes.string),
    "#empty_option": PropTypes.string,
    "#empty_value": PropTypes.string,
  }),
  compositeIndex: PropTypes.number,
  compositeParent: PropTypes.string,
};

export default SelectField;
