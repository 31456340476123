import useDebouncedEffect from "@default/hooks/use-debounced-effect";
import { useFormikContext } from "formik";

/**
 * This component is used to automatically submit the form when the form is valid
 * and has been changed (dirty).
 * @see https://github.com/jaredpalmer/formik/issues/2769
 * @returns {null}
 */
const AutoSubmit = () => {
  const { isValid, values, dirty, submitForm } = useFormikContext();

  /**
   * TODO: submitForm() gets triggered when opening the filters.
   * This should not happen.
   */
  useDebouncedEffect(
    () => {
      if (isValid) {
        void submitForm();
      }
    },
    [isValid, values, dirty, submitForm],
    250
  );

  return null;
};

export default AutoSubmit;
