import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import moment from "moment-timezone";
import getEncodedIcalUrl from "../lib/get-encoded-ical-url";
import SaveTheDateIcon from "@default/assets/ui/save-the-date.svg";
import { encodeUrl } from "@default/lib/encode-url";
import { generateSpeakerName } from "@default/lib/generate-speaker-name";

export const AddToCalendar = ({ content, appearance }) => {
  let date = appearance.fieldDateRawField?.first;

  const eventPassed = moment().diff(moment.unix(date.value)) > 0;

  let venue = "";

  if (appearance?.fieldVenueLocation) {
    venue = appearance.fieldVenueLocation.label;
  }

  if (appearance?.fieldVenueLocation?.fieldAddress?.addressLine1) {
    venue = `${venue} - ${appearance?.fieldVenueLocation.fieldAddress.addressLine1}`;
  }

  if (appearance?.fieldVenueLocation?.fieldAddress?.addressLine2) {
    venue = `${venue} ${appearance?.fieldVenueLocation.fieldAddress.addressLine2}`;
  }

  if (appearance?.fieldVenueLocation?.fieldAddress?.postalCode) {
    venue = `${venue}, ${appearance?.fieldVenueLocation.fieldAddress.postalCode}`;
  }

  if (appearance?.fieldVenueLocation?.fieldAddress?.locality) {
    venue = `${venue}, ${appearance?.fieldVenueLocation.fieldAddress?.locality}`;
  }

  let title = content?.label;

  /**
   * TEMPORARY "BRIDGING"
   * fieldParticipantType can be a string or an array.
   * If the fieldParticipantType is a string, convert it to an array.
   * @see https://office.e-fork.net/issues/15549
   */
  let participantType = content?.fieldParticipantType;
  if (typeof content?.fieldParticipantType === "string") {
    participantType = [content.fieldParticipantType];
  }

  if (
    content.entityBundle === "participant" &&
    content?.fieldParticipantType.includes("speaker")
  ) {
    title = `${generateSpeakerName(content)} @ ${
      appearance.fieldHappening?.label
    }`;
  }

  let event = {
    title: title,
    description: content.fieldText,
    location: venue,
    datetime: date,
  };

  if (!eventPassed) {
    return (
      <div className="add-to-calendar action-element body-m">
        <a
          className="d-flex flex-row"
          href={getEncodedIcalUrl(event)}
          download={(event.title ? encodeUrl(event.title) : "event") + ".ics"}
        >
          <img src={SaveTheDateIcon} alt={"Save the date icon"} />
          <FormattedMessage id={"event.remember"} />
        </a>
      </div>
    );
  }
};

AddToCalendar.propTypes = {
  content: PropTypes.object,
  appearance: PropTypes.object,
};
