import React from "react";
import { FormattedMessage } from "react-intl";
import TeaserSpeakerShort from "@default/teasers/speaker/teaser-speaker-short";
import DateWrapper from "@default/js/participants/components/date-wrapper";
import structuredClone from "@ungap/structured-clone";

const ParticipatingPersons = ({ content }) => {
  const removeDuplicatesById = (content) => {
    const seenIds = new Set();
    if (!!content) {
      return content?.filter((item) => {
        if (item && item.entity && item.entity.id) {
          // Check for null and undefined
          const id = item.entity.id;
          if (seenIds.has(id)) {
            return false;
          } else {
            seenIds.add(id);
            return true;
          }
        }
        return false;
      });
    }
  };

  if (content?.fieldAppearances) {
    let participatingPersonsAreIdentical = true,
      participatingModeratorsAreIdentical = true,
      displayedAppearances = content?.fieldAppearances;

    // Check if all appearing speakers and moderators are identical. If yes, only show first occasion. If not, show all occasions.
    content?.fieldAppearances &&
      content?.fieldAppearances.map((appearance, appearanceIndex) => {
        if (
          appearanceIndex > 0 &&
          JSON.stringify(appearance.fieldSpeakersRawField?.list) !==
            JSON.stringify(
              content?.fieldAppearances[appearanceIndex - 1]
                ?.fieldSpeakersRawField?.list
            )
        ) {
          participatingPersonsAreIdentical = false;
        } else if (
          !!appearance?.fieldModeratorsRawField &&
          JSON.stringify(appearance.fieldModeratorsRawField?.list) !==
            JSON.stringify(
              content?.fieldAppearances[appearanceIndex - 1]
                ?.fieldModeratorsRawField?.list
            )
        ) {
          participatingModeratorsAreIdentical = false;
        }
      });

    if (participatingPersonsAreIdentical && content?.fieldAppearances[0]) {
      displayedAppearances = [content?.fieldAppearances[0]];
    }

    if (
      (!!displayedAppearances &&
        displayedAppearances[0]?.fieldSpeakersRawField?.list?.length > 0) ||
      displayedAppearances[0]?.fieldModeratorsRawField?.list?.length > 0
    ) {
      return (
        <div className="participating-persons">
          <div className="container">
            {displayedAppearances.map((appearance, appearanceIndex) => {
              let speakers = structuredClone(
                  appearance?.fieldSpeakersRawField?.list
                ),
                moderators = structuredClone(
                  appearance?.fieldModeratorsRawField?.list
                );

              speakers = !!speakers && removeDuplicatesById(speakers);
              moderators = !!moderators && removeDuplicatesById(moderators);

              return (
                <div
                  className="row participating-on-appearance"
                  key={appearanceIndex}
                >
                  {/* Check if there is more than one appearance, also check if they are all the same */}

                  {speakers.length > 0 && (
                    <>
                      <div className="col-16 label headline-m">
                        <FormattedMessage id={"speaker"} />
                        {content.fieldAppearances.length > 1 &&
                          !participatingPersonsAreIdentical && (
                            <>
                              {" "}
                              <FormattedMessage id={"on"} />
                              <DateWrapper
                                content={appearance}
                                showTBA={false}
                              />
                            </>
                          )}
                      </div>
                      {speakers.map((speaker, speakerIndex) => (
                        <div
                          className="col-16 col-md-8 teaser-wrapper"
                          key={speakerIndex}
                        >
                          <TeaserSpeakerShort item={speaker.entity} />
                        </div>
                      ))}
                    </>
                  )}

                  {moderators.length > 0 && (
                    <>
                      <div className="col-16 label headline-m">
                        <FormattedMessage id={"moderator"} />
                        {content.fieldAppearances.length > 1 &&
                          !participatingModeratorsAreIdentical && (
                            <>
                              {" "}
                              <FormattedMessage id={"on"} />
                              <DateWrapper
                                content={appearance}
                                showTBA={false}
                              />
                            </>
                          )}
                      </div>
                      {moderators.map((moderator, moderatorIndex) => (
                        <div
                          className="col-16 col-md-8 teaser-wrapper"
                          key={moderatorIndex}
                        >
                          <TeaserSpeakerShort item={moderator.entity} />
                        </div>
                      ))}
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  }
};

export default ParticipatingPersons;
